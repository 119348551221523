import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import List from 'rc-virtual-list';

import { Link } from 'gatsby';

import Icon from 'components/UI/Icon';
import Header from 'sections/ScreverOcxMedia/Header';
import Footer from 'sections/ScreverOcxProgram/Footer';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import 'styles/pages/ocx-gallery.scss';
import 'sections/ScreverOcxMedia/common.scss';

import ScrollImgItem from 'sections/ScreverOcxMedia/ScrollImgItem';

const Gallery = () => {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          'https://screver-ocx-event.test-dev.site/proxy.php'
        );

        // let arr = [{id: 0, images: []}];
        let arr = [];

        for (let index = 0; index < response.data.length / 3; index++) {
          const arr_temp = { id: index, images: [] };

          arr_temp.images.push(response.data[index * 3]);

          if (response.data[index * 3 + 1]) {
            arr_temp.images.push(response.data[index * 3 + 1]);
          }

          if (response.data[index * 3 + 2]) {
            arr_temp.images.push(response.data[index * 3 + 2]);
          }

          // const element1 = response.data[index * 3];
          // const element2 = response.data[index * 3 + 1];
          // const element3 = response.data[index * 3 + 2];

          arr.push(arr_temp);
        }

        // let index = 0;

        // while (index < response.data.length) {
        //   const {height, width} = response.data[index];

        //   const aspectR = height / 300;

        //   const resWidth = width / aspectR;

        //   const last = arr[arr.length - 1];

        //   last.images.reduce((acc, item) => {

        //     const height = item.height / 300;

        //     const width = item.width / height;

        //     return acc+width
        //   }, 0)
        // }

        // setImageData(response.data);
        setImageData(arr);

        // console.log(arr, 'arr');
      } catch (error) {
        console.info(error);
      }
    };

    getData();
  }, []);

  // if (imageData.length === 0) return null;

  const seo = {
    title: 'OCX Event | Gallery | Screver Feedback Management Solution',
    metaDesc:
      'View photos from the Screver OCX Event. We’ll share some exciting news, show off a sneak peek of innovative features, and showcase how AI shapes VoC.',
    image:
      'https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg',
    opengraphUrl: 'https://screver.com/events/screver-ocx/gallery/',
  };

  return (
    <>
      <OcxHelmet robots={'noindex, nofollow, nosnippet'} />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-event-media ocx-media-gallery">
        <Header />

        <div className="ocx-media-wrap link-wrap">
          <Link
            to="/events/screver-ocx/media"
            id="link-to-media-page-from-gallery"
            className="ocx-media-gallery-link"
          >
            <Icon name="arrow-right-bold" /> Back
          </Link>
        </div>

        <main>
          <div className="ocx-media-gallery-wrap">
            {imageData.map((item, idx) => {
              const img1 = item.images[0] || null;
              const img2 = item.images[1] || null;
              const img3 = item.images[2] || null;

              return (
                <div className="ocx-gal-row" key={idx}>
                  <ScrollImgItem data={img1} />

                  {img2 ? <ScrollImgItem data={img2} /> : <></>}

                  {img3 ? <ScrollImgItem data={img3} /> : <></>}
                </div>
              );
            })}
            {/* <List
              data={imageData}
              height={typeof window !== 'undefined' && window.innerHeight - 250}
              itemHeight={280}
              itemKey="id"
            >
              {(img, idx) => {
                const img1 = img.images[0] || null;
                const img2 = img.images[1] || null;
                const img3 = img.images[2] || null;

                return (
                  <div className="ocx-gal-row" key={idx}>
                    <ScrollImgItem data={img1} />

                    {img2 ? <ScrollImgItem data={img2} /> : <></>}

                    {img3 ? <ScrollImgItem data={img3} /> : <></>}
                  </div>
                );
              }}
            </List> */}
          </div>
        </main>

        <div className="ocx-footer-wrap ocx-media-wrap">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Gallery;
