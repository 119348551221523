import React, { useCallback, useState } from 'react';

import GallaryModal from 'sections/ScreverOcxMedia/GallaryModal/index';

import { modalWrap } from './styles.module.scss';

const ScrollImgItem = ({ data }) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen((state) => !state);
  }, []);

  return (
    <div className={modalWrap} onClick={handleClick}>
      <img
        width="464"
        height="300"
        key={data.thumbnail}
        src={data.thumbnail}
        alt={data.name}
        loading="lazy"
      />
      {open ? <GallaryModal data={data} /> : <></>}
    </div>
  );
};

export default ScrollImgItem;
