import React, { useCallback, useState } from 'react';
// import { RotatingLines } from 'react-loader-spinner';

import Icon from 'components/UI/Icon/index';

import { modal, imgStyle, closeBtn } from './styles.module.scss';
import Spinner from './spinner';

const GallaryModal = ({ data }) => {
  const [isLoad, setIsLoad] = useState(false);

  const handleLoad = useCallback(() => {
    setIsLoad((state) => !state);
  }, []);

  return (
    <div className={modal}>
      <button type="button" className={closeBtn}>
        <Icon name="close" />
      </button>

      <img
        src={data.url}
        alt={data.name}
        className={imgStyle}
        onLoad={handleLoad}
      />

      {!isLoad ? <Spinner /> : <></>}
    </div>
  );
};

export default GallaryModal;
